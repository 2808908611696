h1 {
//   text-align: center;
  margin-bottom: 20px;
}

.research-item {
  margin-bottom: 15px;
  cursor: pointer;
  padding: 10px;
//   background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.research-item:hover {
  background-color: #f0f0f0;
  transform: translateY(-3px);
}

.research-item:hover h3 {
    color: black;
    transform: translateY(-3px);
}

.research-item:hover h4 {
    color: black;
    transform: translateY(-3px);
}

.research-item:hover p {
    color: black;
    transform: translateY(-3px);
}

.item-title {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.expanded-content {
  margin-top: 0px;
//   padding: 15px;
//   background-color: #e0e0e0;
  border-radius: 12px;
//   border: 1px solid #ccc;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.expanded-content h4 {
  // margin-top: 0;
  font-size: 1.0em;
  // margin-bottom: 20px;
}

.expanded-content p {
  margin: 5px 0;
  font-size: 1em;
}

.pdf-subtitle-row {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 10px; /* Add some spacing between the icon and the text */
}

.pdf-subtitle-row h4 {
  // margin: 0; /* Remove default margin on the heading */
  font-size: 18px; /* Adjust font size if necessary */
}

.subtitle-row {
  margin-top: 10px;
  margin-bottom: 10px;
}