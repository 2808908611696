/* Timeline Container */
.timeline {
background: var(--primary-color);
display: flex;
justify-content: center;
}

/* Card container */
.card {
position: relative;
max-width: 650px;
}

/* setting padding based on even or odd */
.card:nth-child(odd) {
padding: 30px 0 30px 30px;
}
.card:nth-child(even) {
padding: 30px 30px 30px 0;
}
/* Global ::before */
.card::before {
content: "";
position: absolute;
width: 70%;
border: solid #881cc1;
}

/* Setting the border of top, bottom, left */
.card:nth-child(odd)::before {
left: 0px;
top: -4.5px;
bottom: -4.5px;
border-width: 5px 0 5px 5px;
border-radius: 50px 0 0 50px;
}

/* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
@media only screen and (max-width: 400px) {
.card:nth-child(odd)::before {
    top: -5px;
    bottom: -5px;
}
}

/* Setting the border of top, bottom, right */
.card:nth-child(even)::before {
right: 0;
top: 0;
bottom: 0;
border-width: 5px 5px 5px 0;
border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.card:first-child::before {
border-top: 0;
border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.card:last-child:nth-child(odd)::before {
border-bottom: 0;
border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.card:last-child:nth-child(even)::before {
border-bottom: 0;
border-bottom-right-radius: 0;
}

/* Information about the timeline */
.info {
display: flex;
flex-direction: column;
color: gray;
border-radius: 10px;
padding: 7px;
}

/* Title of the card */
.info .title {
    color: white;
    position: relative;
    margin: 0 0 1% 0;
    padding: 0;
}

.info .subtitle {
    color: white;
    position: relative;
    margin: 0 0 1% 0;
    padding: 0;
}

.info .description {
    color: rgba(255,255,255, 0.8);
    position: relative;
    margin: 0;
    padding: 0;
}

// .title h3 {
//     height: 0;
// }

/* Timeline dot  */
.title::before {
content: "";
position: absolute;
width: 20px;
height: 20px;
background: #881cc1;
border-radius: 999px;
border: 3px solid white;
}

/* text right if the card is even  */
.card:nth-child(even) > .info > .title {
text-align: right;
}

.card:nth-child(even) > .info > .subtitle {
text-align: right;
}

/* setting dot to the left if the card is odd */
.card:nth-child(odd) > .info > .title::before {
left: -45px;
}

/* setting dot to the right if the card is odd */
.card:nth-child(even) > .info > .title::before {
right: -45px;
}
