.projects-container {
    display: flex;
    flex-direction: column;
    padding: 5% 5%;
    text-align: left;
}
  
.projects-container .projects-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
}
  
.projects-container .projects-grid .project {
    text-align: left;
}
  
.project h2 {
    font-size: 20px;
}

.project p {
    font-size: 15px;
}

.project h2:hover {
    text-decoration: underline;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.project-img {
    position: relative;
    width:100%;
    height:300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    transition: transform 0.2s;
    margin-bottom: 20px;
}
  
 
.project-img:hover {
    transform: scale(1.05);
}

@media screen {
    @media (max-width: 1000px) {
        .projects-container {
            display: block;
            padding-left: 5%;
            padding-right: 5%;
        }
  
        .projects-container .projects-grid {
            display: block;
        }
      
        .projects-container .projects-grid .project {
            padding-bottom: 5%;
        }
    }
}