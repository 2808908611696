.skills-container {
    padding: 1% 5% 0% 5%;
    display: flex;
    flex-direction: column;
    text-align: left;

    svg {
        color: white;
    }
}
  
.skills-container .skills-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px 50px;
}
  
.skills-container .skills-grid .skill {
    align-items: center;
    text-align: left;
}

.top-row {
    height: 20%;
    margin:1% 4% 1% 4%;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items:center;
}
.icon-box {
    margin-right:5%;
    display: flex;
    flex-direction: row;
    align-items:center;
    // justify-items:center;
}

// .skill-heading {
    // height:px;
// }


  
.chip {
    color: rgb(39, 40, 34) !important;
    background-color: white !important;
    font-size: 0.8rem !important;
    height: 25px !important;
  
    span {
        font-family: 'Courier Prime', monospace;
        color: rgb(39, 40, 34);
    }
  
    svg {
        height: 15px !important;
    }
}



.flex-chips {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    line-height: 1;
    // margin: 0.5;
    width:85%;

    .chip-title {
        color: white;
        padding-right: 10px;
    }
}

.flex-chips .MuiChip-root {
    margin-right: 0.5em;
    margin-bottom: 0.5em;
}

.flex-chips .MuiChip-outlined {
    background-color: #e0e0e0;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.flex-chips .MuiChip-outlined span {
    color: rgb(39, 40, 34);
}

@media screen {
    @media (max-width: 1964px) {
        .skills-container {
            display: block;
            padding-left: 5%;
            padding-right: 5%;
        }
    
        .skills-container .skills-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        
        .skills-container .skills-grid .skill {
            padding-bottom: 5%;
        }
    }

    @media (max-width: 954px) {
        .skills {
            font-size: 12px;
        }

        .skills-container .skills-grid {
            display: initial;
        }
    }
}