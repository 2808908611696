body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 1rem !important;
}

.main-container {
  position: relative;
  border: none;
  box-shadow: none;
}

.background-imager {
  position: fixed;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  filter: blur(4px);
}

.background-imager.dark-mode  {
  background-color: #0d1116;
  background-image: url("./assets/images/bg-dark.png");
}

.background-imager.light-mode {
  background-color: #f8f9fa;
  background-image: url("./assets/images/bg-light.png");
}

a {
  text-decoration: none;
}

a:hover {
  color: #5000ca;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

p, a, h1, h2, h3, h4, h5, label, button {
  color: white;
  font-family: "Lato", sans-serif;
}

.navigation-bar {
  justify-content: space-between;
  background-color: transparent;

  button {
    font-size: 1.1rem;
    font-family: "Lato", sans-serif;
    text-transform: initial;
    padding: 6px 10px;
  }

  svg {
    cursor: pointer;
  }
}

.navigation-bar-responsive {
  span, p {
    color: #0d1116;
    font-family: "Lato", sans-serif;
  }
}

.container {
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-image {
  width: 100%;
  height: 450px;

  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}

.mobile-menu-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}

.items-container {
  padding: 5% 5% 1% 5%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.light-mode {
  
  .navigation-bar {
    background-color: rgba(0, 0, 0, 0);
  }

  p, a, h1, h2, h3, h4, h5, label, button {
    color: #0d1116;
  }

  a:hover {
    color: #5000ca;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  }

  .navigation-bar svg {
    color: #0d1116;
  }

  // .about-section {
    // background-image: url("./assets/images/bg-light.png");
  // }

  .skills-container svg {
    color: #0d1116;
  }

  .chip {
    background-color: rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .vertical-timeline::before {
    background-color: rgba(0, 0, 0, 0.08);
  }
  .card::before {
    border: solid #d1d3d4;
  }
  .title::before {
    background-color: #d1d3d4;
    border: 3px solid black;
  }
  

  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    color: gray;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid rgba(0, 0, 0, 0.08) !important;
  }

  .flex-chips .chip-title {
    color: #0d1116;
  }

  /* Timeline */
  .info .title {
    color: black;
  }

  .info .subtitle {
    color: rgba(0,0,0, 0.85);
  }

  .info .description {
    color: rgba(0,0,0, 0.7);
  }
}

@media screen {
  @media (max-width: 768px) {
    .items-container {
      padding: 5% 5%;
    }
  }
}